// REACT COMPONENTS
import MapView from './js/components/View/MapView.jsx';

// Simple JS
//import leafletmap from './js/leafletmap';

(function($) {
    Drupal.behaviors.scriptjs = {
        attach: function(context, settings) {

            $(document).ajaxStop(function() {
                //console.log("Triggered ajaxStop handler.");
                var selects = $("#views-exposed-form-natural-search-block-1 .form-item select:not(.element-invisible)");
                var labels = $("#views-exposed-form-natural-search-block-1 .form-item label");
                var label1 = $("<label class='label_search_1'>" + Drupal.t("I am") + "</label>");
                var label2 = $("<label class='label_search_2'>" + Drupal.t("I want to") + "</label>");
                var label3 = $("<label class='label_search_3'>" + Drupal.t("For") + "</label>");
                var label4 = $("<label class='label_search_4'>" + Drupal.t("For which phase of your project?") + "</label>");
                $('div.br').each(function() {
                    $(this).remove();
                });
                labels.each(function() {
                    $(this).remove();
                });
                var i = 0;
                selects.each(function() {
                    $(this).find("option[value='All']").text(Drupal.t('Please choose...'));
                    $(this).before(eval("label" + (i + 1)));
                    $(this).after("<div class='br'>&nbsp;</div>");
                    i++;
                });

                if ($('body.front .pg-block').length > 0) {
                    $('#main-area').hide();
                } else {
                    $('#main-area').show();
                }
            });

            $(".tel > a").on("click", function() {
                $(".phone").slideDown();
            });
        }
    };
})(jQuery);


