import React, {Component} from 'react';
import ReactDOM from 'react-dom';

import Leaflet from '../Leaflet/Leaflet.jsx';
import FormGroup from '../Form/FormGroup.jsx';

class MapView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      filter : {
        project : this.props.projectsSelected || [],
      }
    };
  }

  handleFilters = (e) => {
    this.state.filter.project = e.target.value;

    this.setState(this.state);
  };

  _buildFilters = () => {
    let options = {};

    if (Object.keys(this.props.projects).length <= 1) {
      return;
    }

    Object.keys(this.props.projects).map((index) => {
      let option = this.props.projects[index];
      options[option.value] = option.label;
    });

    return (
      <div class="map__filter">
        <FormGroup
          type={'checkboxes'}
          title={'Project'}
          title_hidden={true}
          name={'project'}
          options={options}
          value={this.state.filter.project}
          onChange={this.handleFilters}/>
      </div>
    );
  };

  _getResults = () => {
    let results = {};

    Object.keys(this.props.results).map((index) => {
      let result = this.props.results[index];

      if (this.state.filter.project.indexOf(result.project) == -1) {
        return;
      }

      results[index] = result;
    });

    return results;
  }

  render() {
    let filterResults = this._getResults();
    return (
      <div className="row">
        <div class="map__leaflet" className="col-xs-12 col-md-8">
          <Leaflet points={filterResults}/>
        </div>
        <div id="map__sidebar" className="col-xs-12 col-md-4">
          {this._buildFilters()}
          <div class="map__results">
            {
              Object.keys(filterResults).map((index) => {
                let result = filterResults[index];

                return (
                  <div class={'map__result'} dangerouslySetInnerHTML={{ __html: result.content }}></div>
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }

}

export default MapView;

(function($) {
  Drupal.behaviors.mapBehavior = {
    attach: (context, settings) => {
      $('#woi-map', context).each(function () {
        const domContainer = document.querySelector('#woi-map');

        if (!domContainer) {
          return;
        }

        const mapItems = domContainer.querySelectorAll('.map__item');

        if (!mapItems) {
          return;
        }

        let results = {};
        let projects = {};

        Object.keys(mapItems).map((index) => {
          let mapItem = mapItems[index];

          if (
            !mapItem.querySelector('.map__item__popin')
            || !mapItem.getAttribute('data-lat')
            || !mapItem.getAttribute('data-lng')
          ) {
            return;
          }

          // Set marker
          results[index] = {
            position : [
              parseFloat(mapItem.getAttribute('data-lat')),
              parseFloat(mapItem.getAttribute('data-lng'))
            ],
            popin : mapItem.querySelector('.map__item__popin').innerHTML,
            content : mapItem.querySelector('.map__item__result').innerHTML,
            project : mapItem.getAttribute('data-project-id'),
          };

          // Set project
          projects[mapItem.getAttribute('data-project-id')] = {
            value : mapItem.getAttribute('data-project-id'),
            label : mapItem.getAttribute('data-project-label'),
          };
        });

        ReactDOM.render(<MapView results={results} projects={projects} projectsSelected={Object.keys(projects)}/>, domContainer);
      });
    }
  };
})(jQuery);

