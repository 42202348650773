import React, {Component} from 'react';

import {MapContainer, Marker, TileLayer, Popup} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

class Leaflet extends React.Component {

  constructor(props) {
    super(props);
  }


  _getIcon = () => {
    return new L.Icon({
      iconUrl: '/sites/all/themes/innoviris/img/marker-icon.svg',
      iconSize: [25, 25],
      iconAnchor: [12.5, 12.5],
      popupAnchor: [0, -25],
    });
  };

  render() {
    return (
      <MapContainer
        style={{width: '100%', height: '600px', overflow: 'hidden'}}
        center={[50.823, 4.35]}
        zoom={12}
        noMoveStart={true}
        dragging={true}
        scrollWheelZoom={true}
        touchZoom={true}
      >
        <TileLayer
          attribution={'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community'}
          url={"https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"}
        />
        {
          Object.keys(this.props.points).map((index) => {
            let point = this.props.points[index];

            return (
              <Marker position={point.position} icon={this._getIcon()}>
                <Popup>
                  <div dangerouslySetInnerHTML={{ __html: point.popin }}></div>
                </Popup>
              </Marker>
            );
          })
        }
      </MapContainer>
    );
  }

}

export default Leaflet;
